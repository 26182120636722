.yout-wrap {
	position: relative;
	margin-bottom: 16px;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	z-index: 5;

	.video-youtube,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; }

	.video-youtube {
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;

		&:after {
			@include pseudo;
			@include sizeblock(100%);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #000;
			opacity: 0.5;
			z-index: 10; }

		&:hover {

			.icon {
				fill: $c-main;
				transform: translate(-50%, -50%) scale(1.1); } }

		.icon {
			position: absolute;
			@include sizeblock(80px);
			@include center;
			fill: #fff;
			z-index: 20;
			@include transish;

			@include media($md) {
				@include sizeblock(48px); } } } }
