.content {

	.ntable {

		&__title {
			margin-bottom: 10px;
			font-size: 14px;
			font-weight: 600;
			color: #000; }

		&__wrap {
			overflow-x: auto;
			margin-bottom: 20px; }

		&__brand {
			display: flex;
			align-items: center;

			.brand-pic {
				width: 32px;
				height: 32px;
				margin-right: 16px; }

			.brand-name {
				display: block;
				line-height: 1.8; } }

		&__legend {

			&-item {
				font-size: 10px;
				font-style: italic;

				.note {
					display: inline-block;
					min-width: 25px;
					margin-right: 5px;
					text-align: right; } } }

		.note {
			color: #FD4C77; }

		a {
			color: $c-bg;
			text-decoration: none;

			&:hover {
				color: $c-main; } }

		table {
			width: 100%;
			min-width: 900px;
			font-size: 14px;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			text-transform: none;
			border-bottom: 1px solid #f9f9f9;

			thead {
				background: $c-main;
				color: #fff;
				font-size: 12px;
				font-weight: 600;
				text-transform: uppercase; }


			th {
				font-weight: 400;
				text-align: left;
				padding: 14px 20px; }


			tr {
				@include transish;

				&:nth-child(even) {
					background: #f9f9f9; } }

			td {
				vertical-align: middle;
				padding: 12px 20px; }

			.head-first {
				width: 75px; }

			.head-brand {
				width: 260px; } } } }
