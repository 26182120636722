:root {
  --marginSm: 50px;
  --marginMd: 80px;
  --marginLg: 120px;
}

@media screen and (max-width: 1150px) {
  :root {
    --marginLg: 90px;
    --marginMd: 70px;
  }
}

@media screen and (max-width: 576px) {
  :root {
    --marginSm: 80px;
    --marginMd: 60px;
  }
}

.content .content-img-overlay:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1cae2;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8d9dca;
}

.table-hover .table-primary:hover {
  background-color: #b0bbda;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0bbda;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7e4c5;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #97cd93;
}

.table-hover .table-success:hover {
  background-color: #b6dcb4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b6dcb4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e5c4;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f3ce91;
}

.table-hover .table-warning:hover {
  background-color: #f7dbad;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f7dbad;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcced0;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9a4a8;
}

.table-hover .table-danger:hover {
  background-color: #fbb6b9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb6b9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1149.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #6182db;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 67, 153, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #379F2F;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(55, 159, 47, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #379F2F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23379F2F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #379F2F;
  box-shadow: 0 0 0 0.2rem rgba(55, 159, 47, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #379F2F;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23379F2F' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #379F2F;
  box-shadow: 0 0 0 0.2rem rgba(55, 159, 47, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #379F2F;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #379F2F;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #379F2F;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #46c53c;
  background-color: #46c53c;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(55, 159, 47, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #379F2F;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #379F2F;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #379F2F;
  box-shadow: 0 0 0 0.2rem rgba(55, 159, 47, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F45057;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(244, 80, 87, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F45057;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F45057' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F45057' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F45057;
  box-shadow: 0 0 0 0.2rem rgba(244, 80, 87, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F45057;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F45057' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F45057' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #F45057;
  box-shadow: 0 0 0 0.2rem rgba(244, 80, 87, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F45057;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F45057;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #F45057;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f78085;
  background-color: #f78085;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 80, 87, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F45057;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F45057;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F45057;
  box-shadow: 0 0 0 0.2rem rgba(244, 80, 87, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1150px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #234399;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #234399;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1150px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

.modal-xl {
  max-width: 1140px;
}

:root {
  --marginSm: 50px;
  --marginMd: 80px;
  --marginLg: 120px;
}

@media screen and (max-width: 1150px) {
  :root {
    --marginLg: 90px;
    --marginMd: 70px;
  }
}

@media screen and (max-width: 576px) {
  :root {
    --marginSm: 80px;
    --marginMd: 60px;
  }
}

.content .content-img-overlay:after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.content .accordion__item {
  margin-bottom: 15px;
}

.content .accordion__content {
  font-size: 14px;
  font-family: "Circe", Arial, Verdana, sans-serif;
}

.content .accordion__content-inner {
  padding: 30px 10px 10px;
}

.content .accordion__title {
  position: relative;
  width: 100%;
  padding: 18px 35px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Circe", Arial, Verdana, sans-serif;
  color: #000;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  background-color: #fff;
  transition: all 0.3s;
  z-index: 1;
}

.content .accordion__title:after {
  content: '';
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  top: 13px;
  right: 17px;
  border-radius: 50%;
  transition: all 0.3s;
  background: linear-gradient(90deg, #197874 0%, #3CDADA 113.24%);
  z-index: 5;
}

.content .accordion__title:before {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  top: 27px;
  right: 28px;
  background-color: transparent;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-135deg);
  transition: all 0.3s;
  z-index: 10;
}

.content .accordion__title:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.content .accordion__title:hover:after {
  background-color: #35C8C7;
}

.content .accordion__title:hover:before {
  border-color: #fff !important;
}

.content .accordion__title span {
  position: relative;
  display: inline-block;
  padding-right: 35px;
  z-index: 10;
}

.content .accordion__title.collapsed:before {
  top: 22px;
  transform: rotate(45deg);
}

.content .list,
.content ul:not([class]) {
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
}

.content .list li,
.content ul:not([class]) li {
  position: relative;
  padding-left: 20px;
  color: #060E41;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 767px) {
  .content .list li,
  .content ul:not([class]) li {
    padding-left: 20px;
  }
}

.content .list li:before,
.content ul:not([class]) li:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0.25em;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #35C8C7;
}

.content .list li:not(:last-child),
.content ul:not([class]) li:not(:last-child) {
  margin-bottom: 10px;
}

.content .list li p,
.content ul:not([class]) li p {
  font-size: inherit;
}

.content .list li > ul,
.content ul:not([class]) li > ul {
  margin-top: 20px;
  margin-bottom: 0;
}

.content .list li > ul li,
.content ul:not([class]) li > ul li {
  font-size: inherit;
}

.content .list li > ul li:before,
.content ul:not([class]) li > ul li:before {
  border: 2px solid #35C8C7;
  background-color: #fff;
  box-sizing: border-box;
}

.content .list li > ul li:last-child,
.content ul:not([class]) li > ul li:last-child {
  padding-bottom: 0;
}

.content .list li:last-child,
.content ul:not([class]) li:last-child {
  padding-bottom: 0;
}

.content p {
  margin-bottom: 16px;
}

.content .count-list,
.content ol:not([class]) {
  counter-reset: list;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}

.content .count-list > li,
.content ol:not([class]) > li {
  position: relative;
  color: rgba(7, 15, 66, 0.5);
  padding-left: 35px;
  padding-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .content .count-list > li,
  .content ol:not([class]) > li {
    padding-left: 25px;
  }
}

.content .count-list > li:before,
.content ol:not([class]) > li:before {
  content: counters(list, ".") " ";
  counter-increment: list;
  position: absolute;
  display: block;
  left: 0;
  top: 3px;
  padding: 4px 6px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  font-family: "Circe", Arial, Verdana, sans-serif;
  background-color: #35C8C7;
  box-sizing: border-box;
}

.content .count-list > li:last-child,
.content ol:not([class]) > li:last-child {
  padding-bottom: 0;
}

.content .count-list > li > ul,
.content ol:not([class]) > li > ul {
  margin-top: 20px;
  margin-bottom: 0;
}

.content .count-list > li > ul li,
.content ol:not([class]) > li > ul li {
  position: relative;
  padding-left: 35px;
  font-size: inherit;
}

@media screen and (max-width: 767px) {
  .content .count-list > li > ul li,
  .content ol:not([class]) > li > ul li {
    padding-left: 15px;
  }
}

.content .count-list > li > ul li:before,
.content ol:not([class]) > li > ul li:before {
  content: '';
  position: absolute;
  display: block;
  background: #35C8C7;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #35C8C7;
  box-sizing: border-box;
}

.content .count-list > li > ul li > ul li:before,
.content ol:not([class]) > li > ul li > ul li:before {
  background-color: #060E41;
}

.content .count-list > li > ol,
.content ol:not([class]) > li > ol {
  margin-top: 20px;
  margin-bottom: 0;
}

.content .count-list > li > ol li,
.content ol:not([class]) > li > ol li {
  position: relative;
  padding-left: 35px;
  font-size: inherit;
}

@media screen and (max-width: 767px) {
  .content .count-list > li > ol li,
  .content ol:not([class]) > li > ol li {
    padding-left: 0px;
  }
}

.content .count-list > li > ol li:before,
.content ol:not([class]) > li > ol li:before {
  position: static;
  display: inline-block;
  margin-right: 5px;
}

.content .list-reset li {
  padding-left: 0 !important;
}

.content .list-reset li:before {
  display: none !important;
}

.content .content-img {
  max-width: 250px;
}

@media screen and (max-width: 575px) {
  .content .content-img {
    max-width: 100%;
  }
}

.content .content-img .img-descr {
  display: block;
  margin-top: 15px;
  color: #828282;
  font-size: 14px;
  text-align: center;
  font-style: italic;
}

.content .content-img--left {
  float: left;
  margin: 0 25px 20px 0;
}

@media screen and (max-width: 575px) {
  .content .content-img--left {
    float: none;
    margin: 0 auto 20px;
  }
}

.content .content-img--left img {
  display: block;
  margin: 0 auto;
}

.content .content-img--right {
  float: right;
  margin: 0 0 20px 25px;
}

@media screen and (max-width: 575px) {
  .content .content-img--right {
    float: none;
    margin: 0 auto 20px;
  }
}

.content .content-img--right img {
  display: block;
  margin: 0 auto;
}

.content .content-img--center {
  max-width: 100%;
  display: block;
  float: none;
  text-align: center;
  margin-bottom: 20px;
}

.content .content-img--center img {
  margin: 0 auto;
}

.content .content-img-overlay {
  position: relative;
}

.content .content-img-overlay:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(318.61px at 36.09% 25.62%, #8D5B8F 0%, #8D5B8F 14.06%, #F5890A 100%);
  mix-blend-mode: screen;
}

@media screen and (max-width: 575px) {
  .content .content-img .img-inner {
    float: none;
    text-align: center;
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 10px 0 20px 0;
  }
  .content .content-img .img-inner img {
    float: none;
    margin: 0;
  }
}

.content img[align='left'] {
  max-width: 40%;
  margin: 0 40px 20px 0;
}

@media screen and (max-width: 575px) {
  .content img[align='left'] {
    max-width: 100%;
    float: none !important;
    margin: 0 auto 20px;
  }
}

.content img[align='right'] {
  max-width: 40%;
  margin: 0 0 20px 40px;
}

@media screen and (max-width: 575px) {
  .content img[align='right'] {
    max-width: 100%;
    float: none !important;
    margin: 0 auto 20px;
  }
}

.content img[align='center'] {
  max-width: 40%;
  margin: 0 auto 20px;
}

@media screen and (max-width: 575px) {
  .content img[align='center'] {
    max-width: 100%;
    float: none !important;
    margin: 0 auto 20px;
  }
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

img {
  display: block;
  max-width: 100%;
}

.content .ntable__title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.content .ntable__wrap {
  overflow-x: auto;
  margin-bottom: 20px;
}

.content .ntable__brand {
  display: flex;
  align-items: center;
}

.content .ntable__brand .brand-pic {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.content .ntable__brand .brand-name {
  display: block;
  line-height: 1.8;
}

.content .ntable__legend-item {
  font-size: 10px;
  font-style: italic;
}

.content .ntable__legend-item .note {
  display: inline-block;
  min-width: 25px;
  margin-right: 5px;
  text-align: right;
}

.content .ntable .note {
  color: #FD4C77;
}

.content .ntable a {
  color: #060E41;
  text-decoration: none;
}

.content .ntable a:hover {
  color: #35C8C7;
}

.content .ntable table {
  width: 100%;
  min-width: 900px;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-transform: none;
  border-bottom: 1px solid #f9f9f9;
}

.content .ntable table thead {
  background: #35C8C7;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.content .ntable table th {
  font-weight: 400;
  text-align: left;
  padding: 14px 20px;
}

.content .ntable table tr {
  transition: all 0.3s;
}

.content .ntable table tr:nth-child(even) {
  background: #f9f9f9;
}

.content .ntable table td {
  vertical-align: middle;
  padding: 12px 20px;
}

.content .ntable table .head-first {
  width: 75px;
}

.content .ntable table .head-brand {
  width: 260px;
}

.content .tab-content {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .content .tab-content {
    margin-top: 20px;
  }
}

.content .nav-tabs {
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 767px) {
  .content .nav-tabs {
    flex-direction: column;
  }
}

.content .nav-tabs .nav-item {
  margin: 0;
  border: none !important;
  border-radius: 0 !important;
  transition: all 0.3s;
}

.content .nav-tabs .nav-item:not(:last-child) {
  margin-right: 30px;
}

@media screen and (max-width: 1149px) {
  .content .nav-tabs .nav-item:not(:last-child) {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .content .nav-tabs .nav-item:not(:last-child) {
    margin-right: 5px;
  }
}

.content .nav-tabs .nav-item:hover .nav-link {
  color: #35C8C7;
}

.content .nav-tabs .nav-link {
  padding: 5px 60px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(7, 15, 66, 0.5);
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0 !important;
  transition: all 0.3s;
}

@media screen and (max-width: 1149px) {
  .content .nav-tabs .nav-link {
    padding: 5px 30px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .content .nav-tabs .nav-link {
    padding-left: 20px;
    font-size: 14px;
    border: none;
    border-left: 2px solid transparent;
  }
}

.content .nav-tabs .nav-link:hover {
  text-decoration: none;
}

.content .nav-tabs .nav-link.active {
  color: #35C8C7;
  border-color: #35C8C7;
  text-decoration: none;
}

.content .nav-tabs .nav-link.active:hover {
  color: #35C8C7;
  cursor: default;
  border-color: #35C8C7;
}

.content a {
  transition: all 0.3s;
}

.content a:not([class]) {
  text-decoration: underline;
  color: #060E41;
  word-break: break-word;
}

.content a:hover {
  text-decoration: none;
}

.content .h1,
.content h1,
.content .h2,
.content h2,
.content .h3,
.content h3,
.content .h4,
.content h4,
.content .h5,
.content h5,
.content .h6,
.content h6 {
  display: block;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 1.3;
  color: #060E41;
}

.content hr {
  position: relative;
  height: 1px;
  border: 0;
  margin: 20px 0;
  background: #ccc;
}

.content hr:after, .content hr:before {
  content: '';
  display: table;
  clear: both;
}

.content .h1,
.content h1 {
  font-size: 46px;
}

@media screen and (max-width: 1149px) {
  .content .h1,
  .content h1 {
    font-size: 36px;
  }
}

.content .h1.small,
.content h1.small {
  font-size: 30px;
}

@media screen and (max-width: 1149px) {
  .content .h1.small,
  .content h1.small {
    font-size: 26px;
  }
}

.content .h2,
.content h2 {
  font-size: 30px;
}

@media screen and (max-width: 1149px) {
  .content .h2,
  .content h2 {
    font-size: 26px;
  }
}

.content .h3,
.content h3 {
  font-size: 20px;
}

.content .h4,
.content h4 {
  font-size: 16px;
}

.content .text-deleted,
.content s {
  text-decoration: line-through;
}

.content .blockquote {
  display: block;
  margin-bottom: 25px;
  padding: 28px 35px;
  font-size: 18px;
  color: #060E41;
  line-height: 1.7;
  font-style: italic;
  letter-spacing: 0.03em;
  border-top: 1px solid #35C8C7;
  border-bottom: 1px solid #35C8C7;
}

@media screen and (max-width: 767px) {
  .content .blockquote {
    padding: 25px 15px;
    font-size: 12px;
  }
}

.content .mark,
.content mark {
  background: #35C8C7;
  color: #fff;
  padding: 0 5px;
}

.content b,
.content strong {
  color: #060E41;
}

a {
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  text-decoration: none;
  outline: none;
}

.yout-wrap {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  z-index: 5;
}

.yout-wrap .video-youtube,
.yout-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.yout-wrap .video-youtube {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.yout-wrap .video-youtube:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 10;
}

.yout-wrap .video-youtube:hover .icon {
  fill: #35C8C7;
  transform: translate(-50%, -50%) scale(1.1);
}

.yout-wrap .video-youtube .icon {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
  z-index: 20;
  transition: all 0.3s;
}

@media screen and (max-width: 767px) {
  .yout-wrap .video-youtube .icon {
    width: 48px;
    height: 48px;
  }
}

.content {
  font-size: 16px;
  line-height: 1.4;
}

a {
  color: #060E41;
}
