.content {

	a {
		@include transish();

		&:not([class]) {
			text-decoration: underline;
			color: $c-bg;
			word-break: break-word; }

		&:hover {
			text-decoration: none; } }

	.h1,
	h1,
	.h2,
	h2,
	.h3,
	h3,
	.h4,
	h4,
	.h5,
	h5,
	.h6,
	h6 {
		display: block;
		font-weight: 800;
		margin-bottom: 20px;
		line-height: 1.3;
		color: $c-bg; }

	hr {
		position: relative;
		height: 1px;
		border: 0;
		margin: 20px 0;
		background: #ccc;

		&:after,
		&:before {
			content: '';
			display: table;
			clear: both; } }

	.h1,
	h1 {
		font-size: 46px;

		@include media($lg) {
			font-size: 36px; }

		&.small {
			font-size: 30px;

			@include media($lg) {
				font-size: 26px; } } }

	.h2,
	h2 {
		font-size: 30px;

		@include media($lg) {
			font-size: 26px; } }


	.h3,
	h3 {
		font-size: 20px; }

	.h4,
	h4 {
		font-size: 16px; }

	.text-deleted,
	s {
		text-decoration: line-through; }

	.blockquote {
		display: block;
		margin-bottom: 25px;
		padding: 28px 35px;
		font-size: 18px;
		color: $c-bg;
		line-height: 1.7;
		font-style: italic;
		letter-spacing: 0.03em;
		border-top: 1px solid $c-main;
		border-bottom: 1px solid $c-main;

		@include media($md) {
			padding: 25px 15px;
			font-size: 12px; } }

	.mark,
	mark {
		background: $c-main;
		color: #fff;
		padding: 0 5px; }

	b,
	strong {
		color: $c-bg; } }

a {
	text-decoration: none;
	transition: color 0.3s ease;

	&:hover {
		text-decoration: underline; }

	&:focus {
		text-decoration: none;
		outline: none; } }
