.content {

	.content-img {
		max-width: 250px;

		@include media($sm) {
			max-width: 100%; }

		.img-descr {
			display: block;
			margin-top: 15px;
			color: #828282;
			font-size: 14px;
			text-align: center;
			font-style: italic; }

		&--left {
			float: left;
			margin: 0 25px 20px 0;

			@include media($sm) {
				float: none;
				margin: 0 auto 20px; }

			img {
				display: block;
				margin: 0 auto; } }

		&--right {
			float: right;
			margin: 0 0 20px 25px;

			@include media($sm) {
				float: none;
				margin: 0 auto 20px; }

			img {
				display: block;
				margin: 0 auto; } }

		&--center {
			max-width: 100%;
			display: block;
			float: none;
			text-align: center;
			margin-bottom: 20px;

			img {
				margin: 0 auto; } }

		&-overlay {
			position: relative;

			&:after {
				@include pseudo;
				@include sizeblock(100%);
				@extend %trbl;
				background: radial-gradient(318.61px at 36.09% 25.62%, #8D5B8F 0%, #8D5B8F 14.06%, #F5890A 100%);
				mix-blend-mode: screen; } }

		@include media($sm) {

			.img-inner {
				float: none;
				text-align: center;
				display: block;
				width: 100%;
				max-width: 100%;
				margin: 10px 0 20px 0;

				img {
					float: none;
					margin: 0; } } } }

	img {

		&[align='left'] {
			max-width: 40%;
			margin: 0 40px 20px 0;

			@include media($sm) {
				max-width: 100%;
				float: none !important;
				margin: 0 auto 20px; } }

		&[align='right'] {
			max-width: 40%;
			margin: 0 0 20px 40px;

			@include media($sm) {
				max-width: 100%;
				float: none !important;
				margin: 0 auto 20px; } }

		&[align='center'] {
			max-width: 40%;
			margin: 0 auto 20px;

			@include media($sm) {
				max-width: 100%;
				float: none !important;
				margin: 0 auto 20px; } } } }

svg {
	display: block;
	width: 100%;
	height: 100%; }

img {
	display: block;
	max-width: 100%; }
