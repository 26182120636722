.content {

	.tab-content {
		margin-top: 30px;

		@include media($md) {
			margin-top: 20px; } }

	.nav-tabs {
		border-bottom: 1px solid #ccc;

		@include media($md) {
			flex-direction: column; }

		.nav-item {
			margin: 0;
			border: none !important;
			border-radius: 0 !important;
			@include transish;

			&:not(:last-child) {
				margin-right: 30px;

				@include media($lg) {
					margin-right: 15px; }

				@include media($md) {
					margin-right: 5px; } }

			&:hover {

				.nav-link {
					color: $c-main; } } }

		.nav-link {
			padding: 5px 60px;
			font-size: 18px;
			font-weight: 600;
			color: $c-text;
			border: none;
			border-bottom: 2px solid $trp;
			border-radius: 0 !important;
			@include transish;

			@include media($lg) {
				padding: 5px 30px;
				font-size: 16px; }

			@include media($md) {
				padding-left: 20px;
				font-size: 14px;
				border: none;
				border-left: 2px solid $trp; }


			&:hover {
				text-decoration: none; }

			&.active {
				color: $c-main;
				border-color: $c-main;
				text-decoration: none;

				&:hover {
					color: $c-main;
					cursor: default;
					border-color: $c-main; } } } } }
