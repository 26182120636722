.content {
	//Маркированный список
	.list,
	ul:not([class]) {
		list-style: none;
		padding: 0;
		margin-bottom: 25px;

		li {
			position: relative;
			padding-left: 20px;
			color: $c-bg;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 0.06em;

			@include media($md) {
				padding-left: 20px; }

			&:before {
				@include pseudo;
				left: 0;
				top: 0.25em;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: $c-main; }

			&:not(:last-child) {
				margin-bottom: 10px; }

			p {
				font-size: inherit; }


			& > ul {
				margin-top: 20px;
				margin-bottom: 0;

				li {
					font-size: inherit;

					&:before {
						border: 2px solid $c-main;
						background-color: #fff;
						box-sizing: border-box; }


					&:last-child {
						padding-bottom: 0; } } }

			&:last-child {
				padding-bottom: 0; } } }

	p {
		margin-bottom: 16px; }

	//Нумерованный список
	.count-list,
	ol:not([class]) {
		counter-reset: list;
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 25px;

		> li {
			position: relative;
			color: $c-text;
			padding-left: 35px;
			padding-bottom: 25px;

			@include media($md) {
				padding-left: 25px; }

			&:before {
				content: counters(list, ".") " ";
				counter-increment: list;
				position: absolute;
				display: block;
				left: 0;
				top: 3px;
				padding: 4px 6px;
				color: #fff;
				font-size: 12px;
				font-weight: 600;
				line-height: 1;
				font-family: $font-1;
				background-color: $c-main;
				box-sizing: border-box; }

			&:last-child {
				padding-bottom: 0; }

			& > ul {
				margin-top: 20px;
				margin-bottom: 0;

				li {
					position: relative;
					padding-left: 35px;
					font-size: inherit;

					@include media($md) {
						padding-left: 15px; }


					&:before {
						@include pseudo;
						background: $c-main;
						left: 0;
						top: 8px;
						width: 8px;
						height: 8px;
						background-color: #fff;
						border: 2px solid $c-main;
						box-sizing: border-box; }

					& > ul {
						li {
							&:before {
								background-color: $c-bg; } } } } }

			& > ol {
				margin-top: 20px;
				margin-bottom: 0;

				li {
					position: relative;
					padding-left: 35px;
					font-size: inherit;

					@include media($md) {
						padding-left: 0px; }

					&:before {
						position: static;
						display: inline-block;
						margin-right: 5px; } } } } }


	.list-reset {

		li {
			padding-left: 0 !important;

			&:before {
				display: none !important; } } } }
