.content {

	.accordion {

		&__item {
			margin-bottom: 15px; }

		&__content {
			font-size: 14px;
			font-family: $font-1;

			&-inner {
				padding: 30px 10px 10px; } }

		&__title {
			position: relative;
			width: 100%;
			padding: 18px 35px;
			font-size: 16px;
			font-weight: 400;
			font-family: $font-1;
			color: #000;
			cursor: pointer;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
			border-radius: 18px;
			background-color: #fff;
			@include transish;
			z-index: 1;

			&:after {
				@include pseudo;
				@include sizeblock(32px);
				top: 13px;
				right: 17px;
				@include circle;
				@include transish;
				background: linear-gradient(90deg, #197874 0%, #3CDADA 113.24%);
				z-index: 5; }

			&:before {
				@include pseudo;
				width: 10px;
				height: 10px;
				top: 27px;
				right: 28px;
				background-color: $trp;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: rotate(-135deg);
				@include transish;
				z-index: 10; }

			&:hover {
				box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);

				&:after {
					background-color: $c-main; }

				&:before {
					border-color: #fff !important; } }

			span {
				position: relative;
				display: inline-block;
				padding-right: 35px;
				// background-color: #fff
				z-index: 10; }

			&.collapsed {

				&:before {
					top: 22px;
					transform: rotate(45deg); } } } } }
